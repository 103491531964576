@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables.scss";
@import "./spacing.scss";
@import "./helper";
@import "./layout";
@import "custom";

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,500;0,700;1,400;1,500;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.turn {
  animation: turn-spin infinite 5s linear
}

@keyframes turn-spin {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

.active-menu {
  color: #db2f69;
  font-weight: 700;
}

.dotted {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #FEF8FB;
}

.dotted li {
  display: inline-block;
  list-style: none;
  height: 25px;
  width: 25px;
  background: #0fa;
  border-radius: 100%;
  margin: 0 0.2em;
  animation: anm 1s linear infinite alternate;
}

@keyframes anm {
  0% {
    transform: scale(0.1);
    filter: hue-rotate(0deg);
    opacity: 0;
  }

  50% {
    transform: scale(0.4);
  }

  100% {
    transform: scale(1);
    filter: hue-rotate(360deg);
  }
}

.dotted li:nth-child(2) {
  animation-delay: 0.4s;
}

.dotted li:nth-child(3) {
  animation-delay: 0.8s;
}