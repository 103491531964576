.menu-header {

  .nav-item {
    &:hover {
      .menu-children {
        visibility: visible;
        transform: translateY(0%);
      }

      .anticon {
        transform: rotate(-180deg);
        transition: all .3s ease-in-out;
      }
    }
  }

  .nav-item.active::before {
    content: "";
    background: rgba(219, 47, 105, .4);
    width: 50%;
    height: 4px;
    position: absolute;
    border-radius: 5px;
    top: 40px;
    left: 25%;
  }

  .menu-children {
    left: -20%;
    opacity: 1;
    white-space: nowrap;
    visibility: hidden;
    transform: translateY(20%);
    transition: all .3s ease-in-out;
    padding-bottom: 15px;
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  }
}