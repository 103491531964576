*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: $bodyColor;
}

a {
  text-decoration: none;
}


#app-layout {
  min-height: 100vh;

  .ant-layout-sider {
    background: #2f4791;
  }

  .ant-menu {
    background: transparent;
    height: calc(100vh - 160px);
    overflow-y: auto;

    &.ant-menu-inline {
      border-right: none;
      padding: 15px;

      .ant-menu-item {
        color: #fff;
        font-weight: 600;
        width: 100%;
        margin: 0;
        border-radius: 5px;
        padding: 24px;
        height: auto;
        line-height: initial;
        text-transform: capitalize;
        font-size: 16px;

        &:after {
          display: none;
        }

        .ant-menu-item-icon {
          font-size: 24px;
        }
      }

      .ant-menu-submenu-selected {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        .ant-menu-item {
          padding-left: 38px !important;
        }
      }

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          color: #fff;
          font-weight: 600;
          width: 100%;
          margin: 0;
          border-radius: 5px;
          padding: 15px;
          height: auto;
          line-height: initial;

          &:after {
            display: none;
          }

          .ant-menu-submenu-arrow {
            color: #fff;
          }

          a {
            color: #fff;
          }
        }

        .ant-menu-item-group-title {
          color: #fff;
        }
      }
    }

    &.ant-menu:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background: #586ca6;
      }
    }

    &.ant-menu {
      .ant-menu-item {
        a {
          color: #fff;
        }
      }
    }

  }

  .logo {
    display: flex;
    align-items: center;
    // gap: 15px;
    // padding: 15px;

    a {
      display: block;
      height: 100%;
      text-align: center;

      img {
        width: 150px;
      }
    }

    .user_name {
      flex: 1;

      a {
        display: block;
        color: #fff;
        text-align: right;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .logout {
    padding: 0 24px;
    text-align: center;
  }
}

.top_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .page_name {
    font-size: 24px;
    text-transform: capitalize;
    color: #2f4791;
    margin-bottom: 0;
  }
}

.app_table {
  height: calc(100vh - 260px);
  overflow-y: auto;
  margin-bottom: 2rem;
}

.app_skeleton {
  height: calc(100vh - 196px);
}

.ant-table {
  font-size: 16px;
}

.wrapper-our_customers {
  background-image: url(../../../public/images/backdrop2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-customers-bg {
  background: #fef8fb6b;
}

@media only screen and (max-width: 1024px) {
  .wrapper-our_customers {
    background: #FEF8FB;
  }

  .our-customers-bg {
    background: none;
  }
}